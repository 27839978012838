export const useEnums = () => {
    const MAP_PRE_FILL_COLOR: string = 'rgba(199, 80, 80, 0.5)';
    const MAP_FILL_COLOR: string = 'rgba(243, 199, 199, 0.5)';
    const MAP_FILL_COLOR_POSITIVE: string = 'rgba(199, 243, 199, 0.5)';

    const BUILDING_MB_MAP: Manas.ClickableMap = {
        name: 'mejasi-a',
        areas: [
            {
                name: 'prizemlje',
                shape: 'poly',
                coords: [0,286, 24,286, 247,296, 662,298, 662,330, 247,344, 120,403, 0,403],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'prvi_kat',
                shape: 'poly',
                coords: [0,122, 24,122, 247,250, 662,264, 662,290, 662,298, 247,296, 24,286, 0,286],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'drugi_kat',
                shape: 'poly',
                coords: [0,0, 40,0, 247,202, 662,230, 662,264, 247,250, 24,122, 0,122],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'treci_kat',
                shape: 'poly',
                coords: [146,0, 247,156, 662,198, 662,230, 247,202, 40,0],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'cetvrti_kat',
                shape: 'poly',
                coords: [190,0, 247,110, 662,166, 662,198, 247,156, 146,0],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'nadgrade',
                shape: 'poly',
                coords: [247,80, 662,142, 662,166, 247,110],
                preFillColor: '',
                fillColor: '',
            },
        ],
    };

    const MAP_MB_PODZEMLJE: Manas.ClickableMap = {
        name: 'mp-podzemlje',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [40, 10, 39, 329, 353, 329, 353, 113, 276, 110, 274, 38, 137, 37, 137, 10],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [353, 112, 353, 330, 509, 330, 509, 336, 668, 337, 668, 11, 430, 10, 430, 112],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MB_GROUND: Manas.ClickableMap = {
        name: 'map-ma',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [6, 313, 121, 345, 148, 249, 84, 230, 78, 245, 29, 232],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [100, 171, 91, 205, 154, 224, 164, 190],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '3',
                shape: 'poly',
                coords: [100, 107, 92, 136, 108, 141, 102, 168, 147, 181, 157, 150, 172, 154, 179, 135, 177, 128],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '4',
                shape: 'poly',
                coords: [66, 97, 41, 186, 74, 196, 101, 107],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '5',
                shape: 'poly',
                coords: [180, 11, 181, 74, 291, 74, 292, 43, 232, 41, 232, 29],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '6',
                shape: 'poly',
                coords: [90, 6, 87, 17, 125, 29, 126, 75, 179, 76, 180, 11],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '7',
                shape: 'poly',
                coords: [87, 21, 66, 96, 116, 109, 120, 95, 146, 95, 147, 77, 125, 76, 126, 30],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '8',
                shape: 'poly',
                coords: [182, 95, 183, 131, 174, 158, 244, 160, 245, 95],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '9',
                shape: 'poly',
                coords: [246, 95, 246, 158, 306, 158, 307, 95],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '10',
                shape: 'poly',
                coords: [335, 94, 335, 159, 369, 159, 369, 94],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '11',
                shape: 'poly',
                coords: [369, 43, 370, 158, 431, 158, 432, 43],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '12',
                shape: 'poly',
                coords: [433, 42, 433, 160, 495, 160, 494, 42],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '13',
                shape: 'poly',
                coords: [496, 95, 495, 160, 530, 160, 530, 95],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '14',
                shape: 'poly',
                coords: [558, 93, 557, 159, 619, 159, 619, 94],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '14',
                shape: 'poly',
                coords: [620, 93, 619, 159, 681, 159, 681, 94],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '15',
                shape: 'poly',
                coords: [573, 43, 573, 76, 649, 78, 649, 92, 682, 94, 682, 42],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MB_FLOOR_1: Manas.ClickableMap = {
        name: 'map-ma',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [31, 218, 2, 319, 34, 328, 63, 228],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [52, 272, 41, 308, 63, 316, 57, 335, 90, 345, 96, 325, 122, 332, 133, 297],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },

            {
                name: '3',
                shape: 'poly',
                coords: [68, 276, 132, 294, 140, 268, 160, 274, 170, 241, 85, 218],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '4',
                shape: 'poly',
                coords: [86, 217, 170, 242, 179, 206, 159, 200, 164, 176, 103, 159],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '5',
                shape: 'poly',
                coords: [103, 94, 94, 123, 110, 127, 104, 156, 165, 173, 181, 116],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '6',
                shape: 'poly',
                coords: [68, 83, 44, 174, 76, 182, 101, 94],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '7',
                shape: 'poly',
                coords: [185, 18, 222, 20, 221, 30, 294, 30, 295, 64, 182, 65, 182, 29, 186, 29, 182, 29],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '8',
                shape: 'poly',
                coords: [133, 6, 131, 15, 126, 15, 128, 62, 182, 63, 180, 29, 157, 21, 164, 15, 133, 6],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '9',
                shape: 'poly',
                coords: [
                    91, 8, 127, 17, 127, 62, 148, 65, 148, 79, 124, 81, 118, 96, 70, 82, 80, 43, 74, 41, 80, 18, 86, 20,
                ],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '10',
                shape: 'poly',
                coords: [185, 82, 185, 121, 177, 146, 212, 147, 212, 168, 247, 168, 246, 83],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '11',
                shape: 'poly',
                coords: [246, 82, 247, 168, 282, 168, 282, 147, 310, 147, 309, 81],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '12',
                shape: 'poly',
                coords: [310, 82, 310, 148, 337, 146, 337, 168, 373, 168, 371, 82],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '13',
                shape: 'poly',
                coords: [372, 30, 372, 168, 408, 168, 408, 147, 434, 147, 434, 30],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '14',
                shape: 'poly',
                coords: [435, 30, 434, 146, 461, 147, 461, 168, 497, 168, 497, 29],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '15',
                shape: 'poly',
                coords: [497, 82, 498, 168, 532, 168, 532, 148, 560, 147, 559, 82],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '16',
                shape: 'poly',
                coords: [559, 81, 560, 146, 591, 146, 591, 169, 622, 168, 621, 81],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '17',
                shape: 'poly',
                coords: [622, 82, 622, 147, 651, 148, 652, 168, 685, 168, 683, 83],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '18',
                shape: 'poly',
                coords: [575, 29, 575, 62, 651, 64, 650, 80, 683, 81, 683, 90, 697, 91, 692, 49, 684, 49, 683, 28],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MB_FLOOR: Manas.ClickableMap = {
        name: 'map-ma',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [31, 218, 2, 319, 34, 328, 63, 228],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [52, 272, 41, 308, 63, 316, 57, 335, 90, 345, 96, 325, 122, 332, 133, 297],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },

            {
                name: '3',
                shape: 'poly',
                coords: [68, 276, 132, 294, 140, 268, 160, 274, 170, 241, 85, 218],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '4',
                shape: 'poly',
                coords: [86, 217, 170, 242, 179, 206, 159, 200, 164, 176, 103, 159],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '5',
                shape: 'poly',
                coords: [103, 94, 94, 123, 110, 127, 104, 156, 165, 173, 181, 116],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '6',
                shape: 'poly',
                coords: [68, 83, 44, 174, 76, 182, 101, 94],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '7',
                shape: 'poly',
                coords: [185, 18, 222, 20, 221, 30, 294, 30, 295, 64, 182, 65, 182, 29, 186, 29, 182, 29],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '8',
                shape: 'poly',
                coords: [133, 6, 131, 15, 126, 15, 128, 62, 182, 63, 180, 29, 157, 21, 164, 15, 133, 6],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '9',
                shape: 'poly',
                coords: [
                    91, 8, 127, 17, 127, 62, 148, 65, 148, 79, 124, 81, 118, 96, 70, 82, 80, 43, 74, 41, 80, 18, 86, 20,
                ],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '10',
                shape: 'poly',
                coords: [185, 82, 185, 121, 177, 146, 212, 147, 212, 168, 247, 168, 246, 83],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '11',
                shape: 'poly',
                coords: [246, 82, 247, 168, 282, 168, 282, 147, 310, 147, 309, 81],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '12',
                shape: 'poly',
                coords: [310, 82, 310, 148, 337, 146, 337, 168, 373, 168, 371, 82],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '13',
                shape: 'poly',
                coords: [372, 30, 372, 168, 408, 168, 408, 147, 434, 147, 434, 30],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '14',
                shape: 'poly',
                coords: [435, 30, 434, 146, 461, 147, 461, 168, 497, 168, 497, 29],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '15',
                shape: 'poly',
                coords: [497, 82, 498, 168, 532, 168, 532, 148, 560, 147, 559, 82],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '16',
                shape: 'poly',
                coords: [559, 81, 560, 146, 591, 146, 591, 169, 622, 168, 621, 81],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '17',
                shape: 'poly',
                coords: [622, 82, 622, 147, 651, 148, 652, 168, 685, 168, 683, 83],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '18',
                shape: 'poly',
                coords: [575, 29, 575, 62, 651, 64, 650, 80, 683, 81, 683, 90, 697, 91, 692, 49, 684, 49, 683, 28],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MB_NADGRADE: Manas.ClickableMap = {
        name: 'mb-nadgrade',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [45, 109, 45, 222, 35, 255, 278, 256, 278, 175, 181, 174, 180, 109],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [279, 110, 278, 254, 354, 254, 355, 111],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '3',
                shape: 'poly',
                coords: [354, 111, 353, 255, 429, 255, 430, 112],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },

            {
                name: '4',
                shape: 'poly',
                coords: [433, 175, 433, 255, 585, 255, 585, 174],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '5',
                shape: 'poly',
                coords: [529, 108, 529, 151, 542, 151, 542, 172, 585, 173, 586, 258, 666, 258, 665, 109],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const BUILDING_MA_MAP: Manas.ClickableMap = {
        name: 'mejasi-b',
        areas: [
            {
                name: 'prizemlje',
                shape: 'poly',
                coords: [564, 286, 499, 288, 160, 286, 159, 313, 503, 351, 563, 332],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'prvi_kat',
                shape: 'poly',
                coords: [564, 242, 500, 227, 160, 256, 160, 286, 501, 288, 563, 287],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'drugi_kat',
                shape: 'poly',
                coords: [563, 193, 503, 164, 158, 225, 158, 255, 499, 227, 563, 242],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'treci_kat',
                shape: 'poly',
                coords: [563, 146, 499, 104, 158, 195, 158, 224, 503, 163, 563, 193],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'cetvrti_kat',
                shape: 'poly',
                coords: [563, 101, 502, 25, 156, 159, 156, 196, 498, 104, 564, 149],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: 'nadgrade',
                shape: 'poly',
                coords: [562, 78, 500, -1, 155, 141, 155, 158, 500, 25, 562, 96],
                preFillColor: '',
                fillColor: '',
            },

        ],
    };

    const MAP_MA_GROUND: Manas.ClickableMap = {
        name: 'map-mb',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [145, 19, 145, 228, 240, 228, 241, 240, 337, 240, 334, 109, 202, 106, 201, 21],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [338, 108, 338, 237, 530, 240, 531, 229, 626, 230, 627, 19, 529, 18, 528, 106],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MA_FLOOR_1: Manas.ClickableMap = {
        name: 'map-mb',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [
                    88, 78, 89, 272, 155, 274, 154, 187, 191, 186, 194, 153, 153, 151, 239, 151, 240, 101, 194, 100,
                    193, 76,
                ],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [
                    154, 252, 193, 252, 193, 280, 245, 280, 246, 261, 299, 260, 298, 151, 194, 150, 194, 185, 154, 185,
                ],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '3',
                shape: 'poly',
                coords: [299, 150, 298, 261, 310, 261, 310, 292, 390, 291, 390, 262, 402, 262, 402, 152],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '4',
                shape: 'poly',
                coords: [402, 152, 402, 261, 427, 262, 427, 296, 495, 296, 495, 263, 506, 263, 504, 153],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '5',
                shape: 'poly',
                coords: [506, 76, 507, 254, 518, 254, 518, 288, 590, 288, 589, 255, 612, 254, 611, 75],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MA_FLOOR: Manas.ClickableMap = {
        name: 'map-mb',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [
                    88, 78, 89, 272, 155, 274, 154, 187, 191, 186, 194, 153, 153, 151, 239, 151, 240, 101, 194, 100,
                    193, 76,
                ],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '2',
                shape: 'poly',
                coords: [
                    154, 252, 193, 252, 193, 280, 245, 280, 246, 261, 299, 260, 298, 151, 194, 150, 194, 185, 154, 185,
                ],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '3',
                shape: 'poly',
                coords: [299, 150, 298, 261, 310, 261, 310, 292, 390, 291, 390, 262, 402, 262, 402, 152],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '4',
                shape: 'poly',
                coords: [402, 152, 402, 261, 427, 262, 427, 296, 495, 296, 495, 263, 506, 263, 504, 153],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
            {
                name: '5',
                shape: 'poly',
                coords: [506, 76, 507, 254, 518, 254, 518, 288, 590, 288, 589, 255, 612, 254, 611, 75],
                preFillColor: '',
                fillColor: MAP_FILL_COLOR_POSITIVE,
            },
        ],
    };

    const MAP_MA_NADGRADE: Manas.ClickableMap = {
        name: 'ma-nadgrade',
        areas: [
            {
                name: '1',
                shape: 'poly',
                coords: [
                    44, 70, 44, 277, 166, 277, 166, 287, 325, 287, 325, 184, 289, 182, 288, 159, 249, 159, 248, 100,
                    166, 97, 166, 70,
                ],
                preFillColor: '',
                fillColor: '',
            },
            {
                name: '2',
                shape: 'poly',
                coords: [
                    289, 159, 289, 180, 325, 182, 325, 286, 532, 286, 531, 277, 658, 277, 656, 72, 535, 70, 535, 123,
                    372, 123, 372, 158,
                ],
                preFillColor: '',
                fillColor: '',
            },
        ],
    };

    return {
        MAP_PRE_FILL_COLOR,
        MAP_FILL_COLOR,
        MAP_FILL_COLOR_POSITIVE,
        BUILDING_MA_MAP,
        MAP_MA_FLOOR_1,
        MAP_MA_FLOOR,
        MAP_MA_GROUND,
        MAP_MA_NADGRADE,
        BUILDING_MB_MAP,
        MAP_MB_FLOOR,
        MAP_MB_FLOOR_1,
        MAP_MB_GROUND,
        MAP_MB_NADGRADE,
        MAP_MB_PODZEMLJE,
    };
};
