import React from 'react';

import FloorLink from './FloorLink';
import Legend from './Legend';

interface Props {
    buildingName: string;
    floor: number;
    buildingLink: string;
    projectName: 'mejasi';
    setFloor: React.Dispatch<React.SetStateAction<number>>;
}

export default function FloorsList({ buildingName, floor, setFloor, buildingLink, projectName }: Props) {
    return (
        <>
            {buildingName !== 'MA' && (
                <FloorLink
                    floorLabel='podzemlje'
                    floorNumber={1}
                    setFloor={setFloor}
                    buildingLink={buildingLink}
                    projectName={projectName}
                    linkTitle='-1 etaža'
                    selected={floor === 1}
                />
            )}

            <FloorLink
                floorLabel='prizemlje'
                floorNumber={2}
                setFloor={setFloor}
                buildingLink={buildingLink}
                projectName={projectName}
                linkTitle='Prizemlje'
                selected={floor === 2}
            />
            <FloorLink
                floorLabel='prvi_kat'
                floorNumber={3}
                setFloor={setFloor}
                projectName={projectName}
                buildingLink={buildingLink}
                linkTitle='Prvi kat'
                selected={floor === 3}
            />
            <FloorLink
                floorLabel='drugi_kat'
                floorNumber={4}
                setFloor={setFloor}
                projectName={projectName}
                buildingLink={buildingLink}
                linkTitle='Drugi kat'
                selected={floor === 4}
            />
            <FloorLink
                floorLabel='treci_kat'
                floorNumber={5}
                setFloor={setFloor}
                projectName={projectName}
                buildingLink={buildingLink}
                linkTitle='Treći kat'
                selected={floor === 5}
            />
            <FloorLink
                floorLabel='cetvrti_kat'
                floorNumber={6}
                setFloor={setFloor}
                projectName={projectName}
                buildingLink={buildingLink}
                linkTitle='Četvrti kat'
                selected={floor === 6}
            />
            <FloorLink
                floorLabel='nadgrade'
                floorNumber={7}
                setFloor={setFloor}
                buildingLink={buildingLink}
                projectName={projectName}
                linkTitle='Nadgrađe'
                selected={floor === 7}
            />
            <Legend />
        </>
    );
}
