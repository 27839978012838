import React from 'react';

import { useWindow } from '../hooks/useWindow';

interface Props {
    // imageARef?: any;
}

export default function Legend({}: Props) {
    const { windowWidth } = useWindow();

    let offset: number = 0;

    const floorsContainer: any = document.getElementsByClassName('floors');

    // if (floorsContainer[0] && imageARef && imageARef.current) {
    //     const map: Manas.ClickableMap = imageARef.current.props.map;
    //     let X: number = 0;
    //     if (map.name === 'building-a') X = map.areas[0].coords[2];
    //     else if (map.name === 'map-mertojak-floor' || map.name === 'map-mertojak-ground') X += 350;
    //     else X = map.areas[0].coords[0] + (map.areas[0].coords[2] - map.areas[0].coords[0]) / 2;

    //     if (windowWidth >= 1200) offset = floorsContainer[0].clientWidth + 100 + X - 80;
    //     else if (windowWidth >= 900 && windowWidth < 1200) offset = floorsContainer[0].clientWidth + 100 + X - 180;
    //     else if (windowWidth >= 730 && windowWidth < 900) offset = X - 130;
    //     else if (windowWidth >= 480 && windowWidth < 730) offset = X - 240;
    //     else offset = 60;
    // }

    if (floorsContainer[0]) {
        return (
            <div className='legend'>
                Legenda: Prodan <div className='redCircle'></div>
            </div>
        );
    }

    return null;
}
