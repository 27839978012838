import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { config } from '../../../config/config';

interface Props {
    name: string;
    setName: React.Dispatch<string>;
    email: string;
    setEmail: React.Dispatch<string>;
    title: string;
    setTitle: React.Dispatch<string>;
    message: string;
    setMessage: React.Dispatch<string>;
    setRecaptcha: React.Dispatch<string | null>;
    agreement: boolean;
    setAgreement: React.Dispatch<boolean>;
    isFormValid: () => boolean;
    sendEmail: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ContactForm({
    name,
    setName,
    email,
    setEmail,
    title,
    setTitle,
    message,
    setMessage,
    setRecaptcha,
    agreement,
    setAgreement,
    isFormValid,
    sendEmail,
}: Props) {
    return (
        <form className='contactContainer' method='POST'>
            <div className='formContainer'>
                <div>Kontaktirajte nas</div>
                <br />
                <label>Ime</label>
                <input name='name' type='text' onChange={(event) => setName(event.target.value)} value={name} />
                <label>Email</label>
                <input name='email' type='text' onChange={(event) => setEmail(event.target.value)} value={email} />
                <label>Naslov</label>
                <input name='subject' type='text' onChange={(event) => setTitle(event.target.value)} value={title} />
                <label>Poruka</label>
                <textarea name='message' onChange={(event) => setMessage(event.target.value)} value={message} />
                <ReCAPTCHA sitekey={config.recaptchaKey} onChange={(value) => setRecaptcha(value)} />
                <button
                    disabled={!isFormValid()}
                    className={!isFormValid() ? 'disabledButton' : ''}
                    onClick={(event) => sendEmail(event)}
                >
                    POŠALJI
                </button>
                <br />
                <input type='checkbox' checked={agreement} onChange={() => setAgreement(!agreement)} />
                <br />
                <div id='agreementText'>
                    Dobrovoljno dajem privolu za korištenje i pohranu mojih osobnih podataka, dostupnih isključivo
                    djelatnicima koji ih koriste za obavljanje radnih zadataka i koji će ih upotrijebiti u svrhu
                    ispunjenja mojih zahtjeva. Svojom suglasnošću ujedno potvrđujem da sam upoznat s činjenicom da u
                    svakom trenutku mogu zatražiti uvid u osobne podatke kojima upravlja Manas d.o.o. i zatražiti
                    njihovu promjenu ili brisanje.
                </div>
            </div>
        </form>
    );
}
