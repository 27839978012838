import dotenv from 'dotenv';
dotenv.config();

const env = process.env.REACT_APP_ENV as string;

interface EnvMapper {
    [index: string]: string;
    production: string;
    test: string;
    development: string;
}

const appUrl: EnvMapper = {
    development: 'http://localhost:3001',
    test: 'https://test.manas.hr',
    production: 'https://manas.hr'
};

export const config = {
    appURL: appUrl[env],
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY as string,
    recaptchaSecret: process.env.REACT_APP_RECAPTCHA_SECRET as string,
    googleMapKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
    credentialsPath: process.env.REACT_APP_CREDENTIALS_PATH as string
};
