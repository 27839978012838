import React from 'react';

import { history } from '../../App';

interface Props {
    floorLabel: string;
    floorNumber: number;
    linkTitle: string;
    buildingLink: string;
    projectName: 'mejasi';
    selected: boolean;
    setFloor: React.Dispatch<React.SetStateAction<number>>;
}

export default function FloorLink({
    floorLabel,
    floorNumber,
    linkTitle,
    buildingLink,
    projectName,
    selected,
    setFloor,
}: Props) {
    return (
        <div
            className={selected ? 'selectedFloor' : ''}
            onClick={() => {
                history.push(`/projekti_u_izgradnji/${projectName}/${buildingLink}/${floorLabel}`);
                setFloor(floorNumber);
            }}
        >
            {linkTitle}
        </div>
    );
}
