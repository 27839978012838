import React from 'react';

import leftImage from '../../../../assets/images/incommingProjects/mejasi/pageImages/hero_1.jpg';
import bottomImage from '../../../../assets/images/incommingProjects/mejasi/pageImages/hero_2.jpg';

export default function Mejasi() {
    return (
        <>
            <div className='projectContainer'>
                <div>
                    <img src={leftImage} alt='leftImage' />
                </div>
                <div className='sectionText'>
                    <h1>Projekt Mejaši</h1>
                    Projekt Mejaši smješten je u istoimenom predjelu grada na istoku Splita, u mirnoj ulici s
                    obiteljskim kućama i manjim zgradama, na svega nekoliko minuta hoda od centra Mall of Split. Premda
                    su u neposrednoj blizini svih sadržaja poput škole, vrtića, najvećeg trgovačkog centra u gradu i
                    jedne od glavnih prometnica, objekti projekta Mejaši nude odmak od buke i svakodnevne gužve. 
                    <br />
                    <br />
                    U ukupno dvije zgrade stambeno-poslovnog kompleksa nalazi se 99 stanova u rasponu od 20 m2 do 71 m2, s
                    funkcionalnim tlocrtima koji nude visoku kvalitetu življenja i odišu kvalitetama modernog doma. Obje
                    zgrade pripadaju energetskom razredu A kategorije, a svaka sadržava prizemlje s poslovnim
                    prostorima, četiri stambene etaže, nadgrađe i dvije zajedničke podzemne etaže s garažama. 
                    <br />
                    <br />
                    Osnovna konstrukcija objekata je armirano-betonska, s toplinski i zvučno izoliranom ETICS fasadom, dok je
                    krovna fasada zaštićena kvalitetnom hidro-izolacijskom membranom. Vanjska stolarija podrazumijeva
                    energetski učinkovite PVC zatvore, a unutarnja protuprovalna i protupožarna vrata. Opremanje stanova
                    uključuje luksuznu sanitariju, keramičke pločice i prvoklasne troslojne hrastove parkete. 
                </div>
            </div>
            <img id='sectionBottomImage' src={bottomImage} alt='bottomImage' />
        </>
    );
}
