import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import Standards from './components/Standards';
import About from './components/About';
import coverImage from '../../assets/images/home/1.jpg';
import standardsBottomImage from '../../assets/images/home/3.jpg';
import CookiePopup from './components/CookiePopup';
import CookieAbout from './components/CookieAbout';

import './Home.scss';

interface Props {
    aboutRef: React.MutableRefObject<any>;
}

export default function Home({ aboutRef }: Props) {
    const [showCookieConsent, setShowCookieConsent] = useState<boolean>(true);
    const [showCookieAbout, setShowCookieAboout] = useState<boolean>(false);

    useEffect(() => {
        const cookie = Cookies.get('consent_cookie');
        if (cookie) {
            setShowCookieConsent(false);
        }
    }, []);

    return (
        <>
            <img id='coverImage' src={coverImage} alt='coverImage' />
            <Standards />
            <img id='standardsBottomImage' src={standardsBottomImage} alt='sectionEndImage' />
            <About aboutRef={aboutRef} />
            {showCookieAbout && <CookieAbout />}
            {showCookieConsent && (
                <CookiePopup
                    closeCookieConsent={() => setShowCookieConsent(false)}
                    toggleCookieAbout={setShowCookieAboout}
                    isToggled={showCookieAbout}
                />
            )}
        </>
    );
}
