import { useState } from 'react';

interface Props {
    images: Array<string>;
}

export const useGallery = ({ images }: Props) => {
    let allImages: Array<string> = [...images];
    if (allImages.length === 7) {
        allImages = [...images, ...images, ...images, ...images];
    }

    const [gallery, setGallery] = useState<Array<string>>(allImages);
    const [slideImages, setSlideImages] = useState<Array<string>>(allImages.slice(0, 4));
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

    const selectImage = (image: string): void => {
        setCurrentImageIndex(gallery.indexOf(image));
    };

    const showNextImage = (): void => {
        const nextIndex: number = (currentImageIndex + 1) % allImages.length;
        setCurrentImageIndex(nextIndex);
        setPreviewSlides(nextIndex);
    };

    const showPreviousImage = (): void => {
        const previousIndex: number = (currentImageIndex + allImages.length - 1) % allImages.length;
        setCurrentImageIndex(previousIndex);
        setPreviewSlides(previousIndex);
    };

    const setPreviewSlides = (imageIndex: number): void => {
        const slidePage: number = Math.floor(imageIndex / 4);
        const firstImageIndex: number = slidePage * 4;
        setSlideImages([
            allImages[firstImageIndex],
            allImages[firstImageIndex + 1],
            allImages[firstImageIndex + 2],
            allImages[firstImageIndex + 3],
        ]);
    };

    return { gallery, currentImageIndex, selectImage, slideImages, showNextImage, showPreviousImage };
};
