import React from 'react';

import Building from '../../../../utils/components/Building';

import building_A_Image from 'assets/images/incommingProjects/mejasi/gallery/FINAL2.jpg';
import A_ground from 'assets/images/incommingProjects/mejasi/mejasiB/floors/B-1.KAT.png';
import MA_1 from 'assets/images/incommingProjects/mejasi/mejasiB/floors/1.png';
import MA_2 from 'assets/images/incommingProjects/mejasi/mejasiB/floors/2.png';
import MA_3 from 'assets/images/incommingProjects/mejasi/mejasiB/floors/3.png';
import MA_4 from 'assets/images/incommingProjects/mejasi/mejasiB/floors/4.png';
import MA_nadgrade from 'assets/images/incommingProjects/mejasi/mejasiB/floors/5.png';
import building_B_Image from 'assets/images/incommingProjects/mejasi/gallery/FINAL10.jpg';
import B_ground from 'assets/images/incommingProjects/mejasi/mejasiA/floors/A1.png';
import MB_1 from 'assets/images/incommingProjects/mejasi/mejasiA/floors/1.png';
import MB_2 from 'assets/images/incommingProjects/mejasi/mejasiA/floors/2.png';
import MB_3 from 'assets/images/incommingProjects/mejasi/mejasiA/floors/3.png';
import MB_4 from 'assets/images/incommingProjects/mejasi/mejasiA/floors/4.png';
import MB_nadgrade from 'assets/images/incommingProjects/mejasi/mejasiA/floors/5.png';
import MB_podzemlje from 'assets/images/incommingProjects/mejasi/mejasiB/floors/Dp1-Dp2.png';

interface Props {
    mejasiA: Manas.Building | undefined;
    mejasiB: Manas.Building | undefined;
}

export default function Apartments({ mejasiA, mejasiB }: Props) {
    const buildingAImages = [building_A_Image, MB_podzemlje, A_ground, MA_1, MA_2, MA_3, MA_4, MA_nadgrade];
    const buildingBImages = [building_B_Image, B_ground, MB_1, MB_2, MB_3, MB_4, MB_nadgrade];

    return (
        <div className='apartmentsContainer'>
            <div className='buildingsContainer'>
                <div className='building'>
                    <div></div>
                    <div className='sectionText'>
                        <h1>Stanovi</h1>
                        Zavirite u unutrašnjost stanova kako biste lakše vizualizirali izgled i koncept vašeg budućeg
                        doma. Pređite preko slike za prikaz dostupnih stanova te kliknite na željeni stan.
                    </div>
                </div>
                {mejasiA && mejasiB ? (
                    <div className='building'>
                        <Building
                            buildingName='MA'
                            buildingTitle='A'
                            buildingData={mejasiA}
                            buildingLink='mejasi_A'
                            projectName='mejasi'
                            images={buildingBImages}
                            showText={true}
                        />
                        <Building
                            buildingName='MB'
                            buildingTitle='B'
                            buildingData={mejasiB}
                            buildingLink='mejasi_B'
                            projectName='mejasi'
                            images={buildingAImages}
                            showText={true}
                        />
                    </div>
                ) : (
                    <div className='lds-roller'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                )}
            </div>
        </div>
    );
}
