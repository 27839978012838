import React from 'react';

interface Props {
    title: string;
    value: string;
    progressBar?: boolean;
}

export default function ProjectDetail({ title, value, progressBar }: Props) {
    return (
        <div className='projectDetails'>
            {!progressBar ? (
                <div>
                    {title}: <br />
                    {value}
                    <br />
                    <br />
                </div>
            ) : (
                <div>
                    {title}
                    <br />
                    <progress className='w3-red' max={100} value={value}></progress>
                </div>
            )}
        </div>
    );
}
