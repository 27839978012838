import { useEnums } from './useEnums';
import { history } from '../../App';

// Mejasi MA imports
import M_MA40 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Poslovni Dp1.pdf';
import M_MA41 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Poslovni Dp2.pdf';

import M_MA1 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni B01.pdf';
import M_MA2 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni B02.pdf';
import M_MA26 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni B03.pdf';
import M_MA27 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni B04.pdf';
import M_MA28 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C01.pdf';
import M_MA29 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C02.pdf';
import M_MA30 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C03.pdf';
import M_MA31 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C04.pdf';
import M_MA32 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C05.pdf';
import M_MA33 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C06.pdf';
import M_MA39 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni C07.pdf';
import M_MA34 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni D01.pdf';
import M_MA35 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni D02.pdf';
import M_MA36 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni D03.pdf';
import M_MA37 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni D04.pdf';
import M_MA38 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/Poslovni D05.pdf';

import M_MA3 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B13_B23_B33_B43.pdf';
import M_MA4 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B14_B24_B34_B44.pdf';
import M_MA5 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B15_B25_B35_B45.pdf';
import M_MA6 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B16_B26_B36_B46.pdf';
import M_MA7 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C11_C21_C31_C41.pdf';
import M_MA8 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C12_C22_C32_C42.pdf';
import M_MA9 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C13_C23_C33_C43.pdf';
import M_MA10 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C14_C24_C34_C44.pdf';
import M_MA11 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C15_C25_C35_C45.pdf';
import M_MA12 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C16_C26_C36_C46.pdf';
import M_MA13 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/C/C17_C27_C37_C47.pdf';
import M_MA14 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/D/D11_D21_D31_D41.pdf';
import M_MA15 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/D/D12_D22_D32_D42.pdf';
import M_MA16 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/D/D13_D23_D33_D43.pdf';
import M_MA17 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/D/D14_D24_D34_D44.pdf';
import M_MA18 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/D/D15_D25_D35_D45.pdf';
import M_MA191 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B11.pdf';
import M_MA201 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B12.pdf';
import M_MA19 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B21_B31_B41.pdf';
import M_MA20 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/B/B22_B32_B42.pdf';

import M_MA21 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Nadgrađe/Cn1.pdf';
import M_MA22 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Nadgrađe/Cn2.pdf';
import M_MA23 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Nadgrađe/Dn1.pdf';
import M_MA24 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Nadgrađe/Dn2.pdf';
import M_MA25 from '../../assets/images/incommingProjects/mejasi/mejasiB/apartments/Nadgrađe/Dn3.pdf';

// Mejasi MB imports
import M_MB9 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/Poslovni A01.pdf';
import M_MB10 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/Poslovni A02.pdf';

import M_MB1 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/A11_A21_A31_A41.pdf';
import M_MB2 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/A12.pdf';
import M_MB3 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/A13_A23_A33_A43.pdf';
import M_MB4 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/A14_A24_A34_A44.pdf';
import M_MB5 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/A15_A25_A35_A45.pdf';
import M_MB6 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/A22_A32_A42.pdf';

import M_MB7 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/Nadgrađe/An1.pdf';
import M_MB8 from '../../assets/images/incommingProjects/mejasi/mejasiA/apartments/Nadgrađe/An2.pdf';

interface Props {
    buildingData: Manas.Building | undefined;
    buildingName: string;
    floor: number;
    projectName: 'mejasi';
    parseFloorNumber: (floorLink: string) => -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}

export const useFloorMap = ({ buildingData, buildingName, floor, projectName, parseFloorNumber }: Props) => {
    const {
        MAP_PRE_FILL_COLOR,
        BUILDING_MA_MAP,
        MAP_MA_FLOOR,
        MAP_MA_FLOOR_1,
        MAP_MA_GROUND,
        MAP_MA_NADGRADE,
        BUILDING_MB_MAP,
        MAP_MB_FLOOR,
        MAP_MB_FLOOR_1,
        MAP_MB_GROUND,
        MAP_MB_NADGRADE,
        MAP_MB_PODZEMLJE,
        MAP_FILL_COLOR_POSITIVE,
        MAP_FILL_COLOR,
    } = useEnums();

    const buildingMap: Manas.ClickableMap = buildingName === 'MA' ? BUILDING_MA_MAP : BUILDING_MB_MAP;

    const apartmentBlueprints: Manas.Blueprints = {
        // A prizemlje
        '21MA': M_MB9,
        '22MA': M_MB10,
        // prvi kat
        '31MA': M_MB1,
        '32MA': M_MB2,
        '33MA': M_MB3,
        '34MA': M_MB4,
        '35MA': M_MB5,
        // ostali katovi
        '41MA': M_MB1,
        '42MA': M_MB6,
        '43MA': M_MB3,
        '44MA': M_MB4,
        '45MA': M_MB5,
        // nadgrađe
        '71MA': M_MB7,
        '72MA': M_MB8,
        // B podzemlje
        '11MB': M_MA40,
        '12MB': M_MA41,
        // prizemlje        
        '21MB': M_MA1,
        '22MB': M_MA2,
        '23MB': M_MA26,
        '24MB': M_MA27,
        '25MB': M_MA28,
        '26MB': M_MA29,
        '27MB': M_MA30,
        '28MB': M_MA31,
        '29MB': M_MA32,
        '210MB': M_MA33,
        '211MB': M_MA39,
        '212MB': M_MA34,
        '213MB': M_MA35,
        '214MB': M_MA36,
        '215MB': M_MA37,
        '216MB': M_MA38,
        // prvi kat
        '31MB': M_MA191,
        '32MB': M_MA201,
        '33MB': M_MA3,
        '34MB': M_MA4,
        '35MB': M_MA5,
        '36MB': M_MA6,
        '37MB': M_MA7,
        '38MB': M_MA8,
        '39MB': M_MA9,
        '310MB': M_MA10,
        '311MB': M_MA11,
        '312MB': M_MA12,
        '313MB': M_MA13,
        '314MB': M_MA14,
        '315MB': M_MA15,
        '316MB': M_MA16,
        '317MB': M_MA17,
        '318MB': M_MA18,
        // katovi
        '41MB': M_MA19,
        '42MB': M_MA20,
        '43MB': M_MA3,
        '44MB': M_MA4,
        '45MB': M_MA5,
        '46MB': M_MA6,
        '47MB': M_MA7,
        '48MB': M_MA8,
        '49MB': M_MA9,
        '410MB': M_MA10,
        '411MB': M_MA11,
        '412MB': M_MA12,
        '413MB': M_MA13,
        '414MB': M_MA14,
        '415MB': M_MA15,
        '416MB': M_MA16,
        '417MB': M_MA17,
        '418MB': M_MA18,
        // nadgrađe
        '71MB': M_MA21,
        '72MB': M_MA22,
        '73MB': M_MA23,
        '74MB': M_MA24,
        '75MB': M_MA25,

    };

    const createFloorMap = () => {
        let MAP: Manas.ClickableMap = { name: '', areas: [] };

        if (buildingName === 'MA') {
            if (floor === 2) MAP = MAP_MA_GROUND;
            if (floor === 3) MAP = MAP_MA_FLOOR_1;
            if (floor > 3 && floor < 7) MAP = MAP_MA_FLOOR;
            if (floor === 7) MAP = MAP_MA_NADGRADE;
        }

        if (buildingName === 'MB') {
            if (floor === 1) MAP = MAP_MB_PODZEMLJE;
            if (floor === 2) MAP = MAP_MB_GROUND;
            if (floor === 3) MAP = MAP_MB_FLOOR_1;
            if (floor > 3 && floor < 7) MAP = MAP_MB_FLOOR;
            if (floor === 7) MAP = MAP_MB_NADGRADE;   
        }

        return MAP;
    };

    const setFloorAvailability = (floor: number, MAP: Manas.ClickableMap) => {
        const floorMap = { ...MAP };
        console.log(floorMap)

        const setAreaColors = (floorMap: any, buildingData: any) => {
            for (let i = 0; i < floorMap.areas.length; i++) {
                let area = floorMap.areas[i];
                if (buildingData[i] === 'da') {
                    area.preFillColor = MAP_FILL_COLOR_POSITIVE;
                    area.fillColor = MAP_FILL_COLOR_POSITIVE;
                }
                if (buildingData[i] === 'ne') {
                    area.preFillColor = MAP_PRE_FILL_COLOR;
                    area.fillColor = MAP_FILL_COLOR;
                }
            }
        };

        if (buildingData && floor >= 0) {
            setAreaColors(floorMap, buildingData[floor]);
        }

        return floorMap;
    };

    const mapHandler = (
        floor: number,
        apartmentNumber: string,
        setFloor: React.Dispatch<React.SetStateAction<number>>,
        buildingFloorLabel?: string
    ): void => {
        if (floor >= 1 && apartmentNumber) openApartmentBlueprint(floor, apartmentNumber);
        else if (buildingFloorLabel) {
            const floorNumber = parseFloorNumber(buildingFloorLabel);
            setFloor(floorNumber);
            openFloorBlueprint(buildingFloorLabel);
        }
    };

    const openApartmentBlueprint = (floor: number, apartmentNumber: string): void => {
        const blueprintIndex: string = (floor === 1 ? '1' : floor === 2 ? '2' : floor === 3 ? '3' : floor === 7 ? '7' : '4') + apartmentNumber.toString() + buildingName;
        const blueprintPdf: string = apartmentBlueprints[blueprintIndex];
        window.open(blueprintPdf, '_blank');
    };

    const openFloorBlueprint = (floorLink: string) => {
        history.push(`/projekti_u_izgradnji/${projectName}/zgrada_${buildingName}/${floorLink}`);
    };

    return { buildingMap, createFloorMap, setFloorAvailability, mapHandler };
};
