import { useState } from 'react';

export const useNotification = () => {
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationText, setNotificationText] = useState<string>('');
    const [notificationType, setNotificationType] = useState<string>('success');

    const prepareNotification = (type: string): void => {
        setNotificationType(type);
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 5000);
    };

    return {
        showNotification,
        setShowNotification,
        notificationText,
        setNotificationText,
        notificationType,
        setNotificationType,
        prepareNotification,
    };
};
