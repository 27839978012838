import React from 'react';
import { Link } from 'react-router-dom';

import aboutImage from '../../../assets/images/home/4.jpg';
import kontaktArrow from '../../../assets/icons/KONTAKT-STRELICA.png';

interface Props {
    aboutRef: React.MutableRefObject<any>;
}

export default function About({ aboutRef }: Props) {
    return (
        <div className='sectionWrapper'>
            <div className='sectionContainer'>
                <img ref={aboutRef} id='aboutImage' src={aboutImage} alt='aboutImage' />
                <div className='sectionText'>
                    <h1>O nama</h1>
                    Tvrtka Manas d.o.o. osnovana je 1990. godine u Splitu i djeluje kao investitor u projektima
                    stambeno-poslovne izgradnje, u segmentima djelatnosti kao što su odabir parcele i ishođenje svih
                    potrebnih dozvola, vođenje projekta, organizacija gradnje i u konačnici, prodaja stambenih i
                    poslovnih prostora.
                    <br />
                    <br />
                    Slijedeći princip poslovanja koji se zasniva na suvremenom dizajnu, visokoj kvaliteti izgradnje i
                    profesionalnom ophođenju s klijentima, tvrtka je realizirala nekoliko uspješnih projekata na
                    području grada Splita i okolice.
                </div>
            </div>
            <Link to='/kontakt' onClick={() => window.scrollTo(0, 0)}>
                <img id='contactLink' src={kontaktArrow} alt='arrowLogo' className='linkImage' />
            </Link>
        </div>
    );
}
