import React from 'react';

export default function CookieAbout() {
    return (
        <div className='cookieAboutContainer'>
            <div className='aboutText'>
                <h1>KOLAČIĆI</h1>
                Kolačić (cookie) je informacija koju web stranica koju ste posjetili sprema na vaše računalo. Kolačići
                obično spremaju vaše postavke i postavke za web stranicu, npr. preferirani jezik ili adresu. Kasnije,
                kada ponovno posjetite istu web stranicu, vaš preglednik šalje natrag kolačiće te stranice. Drugim
                riječima, ovaj proces omogućava stranici da prikaže informacije prilagođene vašim potrebama.
                <br />
                <br />
                Kolačići mogu spremati širok pojas informacija uključujući osobne informacije, npr. vaše ime ili e-mail
                adresu, ali samo ako vi to omogućite. Web stranice nemaju pristup informacijama koji im vi niste dali i
                ne mogu pristupiti drugim datotekama na vašem računalu.
                <br />
                <br />
                <h1>PRIHVAĆANJE</h1>
                Kolačiće možete odobriti ili odbiti, a osim konfiguracije istih, vaš preglednik vam pruža i mogućnost da
                izbrišete dosad automatski spremljene kolačiće.
                <br />
                <br />
                Zadane aktivnosti spremanja i slanja kolačića vama nisu vidljive, međutim, sami odlučujete hoćete
                dopustiti pohranjivanje kolačića na vašem računalu. Blokiranjem kolačića možete nastaviti s
                pregledavanjem naših web stranica, ali vam u tom slučaju neke njezine mogućnosti neće biti dostupne.
                <br />
                <br />
                <h1>TIPOVI KOLAČIĆA</h1>
                <h2>Što su privremeni kolačići?</h2>
                Privremeni kolačići ili kolačići sesije uklanjaju se s računala po zatvaranju internet preglednika.
                <br />
                <br />
                <h2>Što su stalni kolačići?</h2>
                Stalni ili spremljeni kolačići ostaju na računalu nakon zatvaranja programa internet preglednika, a na
                računalu mogu biti pohranjeni danima, mjesecima, čak i godinama.
                <br />
                <br />
                <h2>Što su kolačići od prve strane?</h2>
                Kolačići od prve strane dolaze s web stranica koje pregledavate, a mogu biti stalni ili privremeni.
                Pomoću tih kolačića web stranice mogu pohraniti podatke koje će ponovno koristiti prilikom sljedećeg
                posjeta toj stranici.
                <br />
                <br />
                <h2>Što su kolačići treće strane?</h2>
                Kolačići treće strane dolaze s reklama drugih web stranica (kao što su skočne ili druge reklame) koje se
                nalaze na web stranicama koje pregledavate. Pomoću tih kolačića web-mjesta mogu pratiti korištenje
                interneta u marketinške svrhe.
                <br />
                <br />
            </div>
        </div>
    );
}
