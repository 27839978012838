import React from 'react';

interface Props {
    title: string;
    logo: string;
    image: string;
    apartments?: string;
    bussinesSpaces?: string;
    garages?: string;
    className: string;
}

export default function Project({ title, logo, apartments, bussinesSpaces, garages, image, className }: Props) {
    return (
        <div className={`project ${className}`}>
            <div className='title'>
                <img src={logo} alt='logo' />
                {title}
                {apartments && <p>{apartments}</p>}
                {bussinesSpaces && <p>{bussinesSpaces}</p>}
                {garages && <p>{garages}</p>}
            </div>
            <img src={image} alt='projektBrda' />
        </div>
    );
}
