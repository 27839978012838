import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

import locationIcon from '../../assets/icons/MANAS-LOCATION.png';

interface ManasMapProps {
    googleMapURL: string;
    loadingElement: JSX.Element;
    containerElement: JSX.Element;
    mapElement: JSX.Element;
    location: any;
}

const MapComponent = withScriptjs(
    withGoogleMap<ManasMapProps>(props => (
        <GoogleMap defaultZoom={14.8} defaultCenter={props.location}>
            <Marker position={props.location} defaultIcon={locationIcon} />
        </GoogleMap>
    ))
) as any;

export default MapComponent;
