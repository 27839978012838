import React from 'react';

import { config } from '../../../config/config';
import { useWindow } from '../../hooks/useWindow';

import MapComponent from '../MapComponent';
import logoFooter from '../../../assets/icons/LOGO-FOOTER.png';
import locationIcon from '../../../assets/icons/LOKACIJA.png';
import phoneIcon from '../../../assets/icons/MOB.png';
import mailIcon from '../../../assets/icons/MAIL.png';

import './Footer.scss';

export default function Footer() {
    const { windowWidth } = useWindow();

    return (
        <div className='footer'>
            <div id='info'>
                <img src={logoFooter} alt='footerLogo' height={30} />
                <div id='footerData'>
                    <h1>Manas d.o.o.</h1>
                    <div className='dataRow'>
                        <img src={locationIcon} alt='location' />
                        <div>Sarajevska 46E, 21000 Split</div>
                    </div>
                    <div className='dataRow'>
                        <img src={phoneIcon} alt='phone' />
                        {windowWidth <= 780 ? (
                            <div>
                                <a href='tel:+ 385 98 323 233'>+ 385 98 323 233</a>
                            </div>
                        ) : (
                            <div>+ 385 98 323 233</div>
                        )}
                    </div>
                    <div className='dataRow'>
                        <img src={mailIcon} alt='mail' />
                        <div>
                            <a href='mailto:prodaja@manas.hr?subject=&body=' target='_blank'>
                                prodaja@manas.hr
                            </a>
                        </div>
                    </div>
                </div>
                <div id='copyright'>{'\u00a9'} Copyright {new Date().getFullYear()}</div>
            </div>
            <div id='location'>
                <MapComponent
                    googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + config.googleMapKey}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    location={{ lat: 43.52454, lng: 16.473743 }}
                />
            </div>
        </div>
    );
}
