import React from 'react';

import { useContactForm } from '../../utils/hooks/useContactForm';

import ContactForm from './components/ContactForm';
import Notification from '../../utils/components/Notification';

import './Contact.scss';

export default function Contact() {
    const {
        notificationText,
        notificationType,
        showNotification,
        name,
        setName,
        email,
        setEmail,
        title,
        setTitle,
        message,
        setMessage,
        setRecaptcha,
        agreement,
        setAgreement,
        isFormValid,
        sendEmail,
    } = useContactForm();

    return (
        <>
            <ContactForm
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                title={title}
                setTitle={setTitle}
                message={message}
                setMessage={setMessage}
                setRecaptcha={setRecaptcha}
                agreement={agreement}
                setAgreement={setAgreement}
                isFormValid={isFormValid}
                sendEmail={sendEmail}
            />
            <Notification
                notificationText={notificationText}
                notificationType={notificationType}
                showNotification={showNotification}
            />
        </>
    );
}
