import React from 'react';
import Select from 'react-select';

import { history } from '../../App';
import { useBuilding } from 'utils/hooks/useBuilding';

interface Props {
    buildingName: string;
    buildingLink: string;
    projectName: 'mejasi';
    setFloor: React.Dispatch<React.SetStateAction<number>>;
}

export default function FloorMenu({ buildingName, buildingLink, projectName, setFloor }: Props) {
    const { parseFloorNumber } = useBuilding();
    
    return (
        <>
            {buildingName === 'MA' ? (
                <Select
                    className='selectMenu'
                    options={[
                        { value: 'prizemlje', label: 'Prizemlje' },
                        { value: 'prvi_kat', label: 'Prvi kat' },
                        { value: 'drugi_kat', label: 'Drugi kat' },
                        { value: 'treci_kat', label: 'Treći kat' },
                        { value: 'cetvrti_kat', label: 'Četvrti kat' },
                        { value: 'nadgrade', label: 'Nadgrađe' },
                    ]}
                    onChange={(selectedOption) => {
                        history.push(`/projekti_u_izgradnji/${projectName}/${buildingLink}/${selectedOption!.value}`);
                        setFloor(parseFloorNumber(selectedOption!.value));
                    }}
                />
            ) : (
                <Select
                    className='selectMenu'
                    options={[
                        { value: 'podzemlje', label: '-1 etaža' },
                        { value: 'prizemlje', label: 'Prizemlje' },
                        { value: 'prvi_kat', label: 'Prvi kat' },
                        { value: 'drugi_kat', label: 'Drugi kat' },
                        { value: 'treci_kat', label: 'Treći kat' },
                        { value: 'cetvrti_kat', label: 'Četvrti kat' },
                        { value: 'nadgrade', label: 'Nadgrađe' },
                    ]}
                    onChange={(selectedOption) => {
                        history.push(`/projekti_u_izgradnji/${projectName}/${buildingLink}/${selectedOption!.value}`);
                        setFloor(parseFloorNumber(selectedOption!.value));
                    }}
                />
            )}
        </>
    );
}
