import { useState, useEffect, useRef } from 'react';
import { history } from '../../App';

export const useHeader = () => {
    const [openHomeDropdown, setOpenHomeDropdown] = useState<boolean>(false);
    const [openProjectsDropdown, setOpenProjectsDropdown] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<string>(window.location.pathname.split('/')[1]);
    const [finishedProjectsHovered, setFinishedProjectsHovered] = useState<boolean>(false);
    const [contactHovered, setContactHovered] = useState<boolean>(false);

    const homeDropRef: any = useRef(null);
    const projectsDropRef: any = useRef(null);
    const menuRef: any = useRef(null);
    const openMenuRef: any = useRef(null);

    history.listen((location, action) => setCurrentPage(location.pathname.split('/')[1]));

    const handleClickOutsideMenu = (event: any) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            openMenuRef.current &&
            !openMenuRef.current.contains(event.target)
        ) {
            setOpenMenu(false);
            setOpenHomeDropdown(false);
        }
    };

    const handleClickOutsideHomeDropdown = (event: any) => {
        if (homeDropRef.current && !homeDropRef.current.contains(event.target)) {
            setOpenHomeDropdown(false);
        }
    };

    const handleClickOutsideProjectsDropdown = (event: any) => {
        if (projectsDropRef.current && !projectsDropRef.current.contains(event.target)) {
            setOpenProjectsDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideHomeDropdown, true);
        document.addEventListener('click', handleClickOutsideProjectsDropdown, true);
        document.addEventListener('click', handleClickOutsideMenu, true);
        return () => {
            document.removeEventListener('click', handleClickOutsideHomeDropdown, true);
            document.removeEventListener('click', handleClickOutsideProjectsDropdown, true);
            document.removeEventListener('click', handleClickOutsideMenu, true);
        };
    });

    return {
        openHomeDropdown,
        setOpenHomeDropdown,
        openProjectsDropdown,
        setOpenProjectsDropdown,
        openMenu,
        setOpenMenu,
        homeDropRef,
        projectsDropRef,
        openMenuRef,
        menuRef,
        currentPage,
        finishedProjectsHovered,
        setFinishedProjectsHovered,
        contactHovered,
        setContactHovered,
    };
};
