import React from 'react';

import Apartments from './components/Apartments';
import Gallery from '../../../utils/components/Gallery';
import Mejasi from './components/Mejasi';
import ProjectDetails from '../../../utils/components/projectDetails/ProjectDetails';

import image_1 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL1.jpg';
import image_2 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL2.jpg';
import image_3 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL3.jpg';
import image_4 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL4.jpg';
import image_5 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL5.jpg';
import image_6 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL6.jpg';
import image_7 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL7.jpg';
import image_8 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL8.jpg';
import image_9 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL9.jpg';
import image_10 from '../../../assets/images/incommingProjects/mejasi/gallery/FINAL10.jpg';

interface Props {
    projectDetails?: Manas.ProjectDetails;
    buildingA?: Manas.Building;
    buildingB?: Manas.Building;
}

export default function ProjectMejasi({ projectDetails, buildingA, buildingB }: Props) {
    const galleryImages = [image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9, image_10];

    return (
        <>
            <Mejasi />
            <Apartments mejasiA={buildingA} mejasiB={buildingB} />
            <Gallery images={galleryImages} />
            <ProjectDetails projectDetails={projectDetails} location={{ lat: 43.519142, lng: 16.488966 }} />
        </>
    );
}
