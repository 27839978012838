import React from 'react';

import Project from './components/Project';

import kmanImage from '../../assets/images/finishedProjects/KMAN.jpg';
import mertojakImage from '../../assets/images/finishedProjects/MERTOJAK.jpg';
import kilaImage from '../../assets/images/finishedProjects/KILA.jpg';
import brdaImage from '../../assets/images/finishedProjects/BRDA.jpg';
import znjanImage from '../../assets/images/finishedProjects/ZNJAN.jpg';
import pazdigradImage from '../../assets/images/finishedProjects/PAZDIGRAD.jpg';
import solinImage from '../../assets/images/finishedProjects/SOLIN.jpg';
import manasLogo from '../../assets/icons/RED-LOGO.png';
import radovicLogo from '../../assets/icons/RADOVIC-GRADNJA-LOGO.png';

import './FinishedProjects.scss';

export default function FinishedProjects() {
    return (
        <div className='projectsContainer'>
            <div className='projectsGrid'>
                <Project
                    className='secondaryGrey'
                    title='Projekt Kman'
                    logo={manasLogo}
                    image={kmanImage}
                    apartments={'42 stambene jedinice'}
                    garages={'47 garaža'}
                />                
                <Project
                    className='primaryGrey'
                    title='Projekt Mertojak'
                    logo={manasLogo}
                    image={mertojakImage}
                    apartments={'21 stambena jedinica'}
                    garages={'22 garaže'}
                />
                <Project
                    className='secondaryGrey'
                    title='Projekt Kila'
                    logo={radovicLogo}
                    image={kilaImage}
                    apartments={'156 stambenih jedinica'}
                    bussinesSpaces={'3 poslovna prostora'}
                    garages={'154 garaže'}
                />
                <Project
                    className='primaryGrey'
                    title='Projekt Brda'
                    logo={manasLogo}
                    image={brdaImage}
                    apartments={'56 stambenih jedinica'}
                    bussinesSpaces={'3 poslovna prostora'}
                    garages={'60 garaža'}
                />
                <Project
                    className='secondaryGrey'
                    title='Projekt Solin'
                    logo={manasLogo}
                    image={solinImage}
                    apartments={'25 stambenih jedinica'}
                    garages={'2 garaže'}
                />
                <Project
                    className='primaryGrey'
                    title='Projekt Pazdigrad'
                    logo={manasLogo}
                    image={pazdigradImage}
                    apartments={'19 stambenih jedinica'}
                    bussinesSpaces={'2 poslovna prostora'}
                    garages={'20 garaža'}
                />
                <Project
                    className='secondaryGrey'
                    title='Projekt Žnjan'
                    logo={manasLogo}
                    image={znjanImage}
                    apartments={'17 stambenih jedinica'}
                    garages={'28 garaža'}
                />
            </div>
        </div>
    );
}
