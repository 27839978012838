import { useState, useEffect } from 'react';

import { getSheetData } from '../sheets';

export const useGoogleSheet = () => {
    const [mejasiDetails, setMejasiDetails] = useState<Manas.ProjectDetails>();
    const [mejasiA, setMejasiA] = useState<Manas.Building>();
    const [mejasiB, setMejasiB] = useState<Manas.Building>();

    useEffect(() => {
        (async () => {
            const {
                mejasiDetails,
                mejasiA,
                mejasiB,
            } = await getSheetData();
            setMejasiDetails(mejasiDetails);
            setMejasiA(mejasiA);
            setMejasiB(mejasiB);
        })();
    }, []);

    return {
        mejasiDetails,
        mejasiA,
        mejasiB,
    };
};
