import React from 'react';

import { config } from '../../../config/config';
import MapComponent from '../MapComponent';
import ProjectDetail from './components/ProjectDetail';

interface Props {
    projectDetails: Manas.ProjectDetails | undefined;
    location: { lat: number; lng: number };
}

export default function ProjectDetails({ projectDetails, location }: Props) {
    return (
        <div className='detailsContainer'>
            <h1>Detalji projekta</h1>
            <div className='detailsLocation'>
                {projectDetails && (
                    <div className='datesContainer'>
                        <ProjectDetail title='Početak gradnje' value={projectDetails.buildingStartDate} />
                        <ProjectDetail title='Planirani završetak gradnje' value={projectDetails.buildingEndDate} />
                    </div>
                )}
                <MapComponent
                    googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + config.googleMapKey}
                    loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                    containerElement={<div style={{ height: `100%`, width: '100%' }} />}
                    mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                    location={location}
                />
            </div>
            {projectDetails && (
                <div className='detailsGrid'>
                    <ProjectDetail title='Dovršetak' value={projectDetails.progressPercentage} progressBar />
                    <ProjectDetail
                        title='Planiranje i projektiranje'
                        value={projectDetails.planningPercentage}
                        progressBar
                    />
                    <ProjectDetail title='Faza gradnje' value={projectDetails.buildingPhasePercentage} progressBar />
                    <ProjectDetail
                        title='Uređivanje interijera'
                        value={projectDetails.interierPercentage}
                        progressBar
                    />
                </div>
            )}
        </div>
    );
}
