export const useBuilding = () => {
    const findNewImage = (images: string[], buildingName: string, floor: number) => {       
        let image : string;
        
        if (buildingName === 'MA') {  
            image = floor !== -1 ? images[floor - 1] : images[0]
            
            return image
        } else if (buildingName === 'MB') {
            image = floor !== -1 ? images[floor] : images[0]
            
            return image
        }
        
        return images[0];
    };

    const parseFloorNumber = (floorLink: string) => {
        if (floorLink === 'podzemlje') {
            return 1;
        } else if (floorLink === 'prizemlje') {
            return 2;
        } else if (floorLink === 'prvi_kat') {
            return 3;
        } else if (floorLink === 'drugi_kat') {
            return 4;
        } else if (floorLink === 'treci_kat') {
            return 5;
        } else if (floorLink === 'cetvrti_kat') {
            return 6;
        } else if (floorLink === 'nadgrade') {
            return 7;
        } else {
            return -1;
        }
    };

    const calculateMapperWidth = (windowWidth: number): number => {
        let mapperWidth = 0;
        if (windowWidth >= 1900) mapperWidth = (((windowWidth * 55) / 100) * 74) / 100;
        else if (windowWidth >= 1680 && windowWidth < 1900) mapperWidth = (((windowWidth * 55) / 100) * 74) / 100;
        else if (windowWidth >= 1440 && windowWidth < 1680) mapperWidth = (((windowWidth * 55) / 100) * 74) / 100;
        else if (windowWidth >= 1366 && windowWidth < 1440) mapperWidth = (((windowWidth * 55) / 100) * 73.5) / 100;
        else if (windowWidth >= 1280 && windowWidth < 1366) mapperWidth = (750 * 73) / 100;
        else if (windowWidth >= 1024 && windowWidth < 1280) mapperWidth = (750 * 71.5) / 100;
        else if (windowWidth <= 600) mapperWidth = (windowWidth * 70) / 100;
        else mapperWidth = (750 * 71.5) / 100;

        return mapperWidth;
    };

    return { findNewImage, calculateMapperWidth, parseFloorNumber };
};
