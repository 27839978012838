import { useState } from 'react';
import axios from 'axios';

import { config } from '../../config/config';
import { useNotification } from './useNotification';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const useContactForm = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [recaptcha, setRecaptcha] = useState<string | null>('');
    const [agreement, setAgreement] = useState<boolean>(false);

    const { showNotification, notificationText, setNotificationText, notificationType, prepareNotification } =
        useNotification();

    const isFormValid = (): boolean => {
        if (name !== '' && email !== '' && title !== '' && message !== '' && recaptcha !== '' && agreement) {
            return true;
        }

        return false;
    };

    const sendEmail = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        const requestData: Manas.MailRequest = {
            name: name,
            email: email,
            title: title,
            message: message,
        };

        axios({
            method: 'POST',
            url: `${config.appURL}/send_mail`,
            headers: { 'Content-Type': 'application/json' },
            data: { ...requestData },
        })
            .then((response) => {
                if (response.data.status === 0) {
                    setNotificationText(response.data.message);
                    prepareNotification('success');
                } else {
                    setNotificationText(response.data.message);
                    prepareNotification('error');
                }
            })
            .catch((error) => {
                console.error(error);
                setNotificationText('Došlo je do pogreške prilikom slanja emaila.');
                prepareNotification('error');
            });
    };

    return {
        name,
        setName,
        title,
        setTitle,
        email,
        setEmail,
        message,
        setMessage,
        agreement,
        setAgreement,
        recaptcha,
        setRecaptcha,
        showNotification,
        notificationType,
        notificationText,
        isFormValid,
        sendEmail,
    };
};
