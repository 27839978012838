import React from 'react';
import { Link } from 'react-router-dom';

import { useHeader } from '../../../hooks/useHeader';
import { history } from '../../../../App';

import logo from '../../../../assets/icons/LOGO-FOOTER.png';

interface Props {
    aboutRef: React.MutableRefObject<any>;
    goToPageSection: (destinationPathname: string, offset: number, sectionRef?: any) => Promise<void>;
}

export default function MobileHeader({ aboutRef, goToPageSection }: Props) {
    const {
        openHomeDropdown,
        setOpenHomeDropdown,
        openProjectsDropdown,
        setOpenProjectsDropdown,
        openMenu,
        setOpenMenu,
        openMenuRef,
        menuRef,
    } = useHeader();

    return (
        <>
            <div className='mobileHeaderContent'>
                <img
                    className='logo'
                    src={logo}
                    alt='logo'
                    onClick={() => {
                        history.push('/');
                        window.scrollTo(0, 0);
                    }}
                />
                <i ref={openMenuRef} className='fas fa-bars' onClick={() => setOpenMenu(!openMenu)} />
            </div>
            {openMenu && (
                <div className='menuContainer' ref={menuRef}>
                    <div className='linkRow'>
                        <Link
                            to='/'
                            onClick={() => {
                                window.scrollTo(0, 0);
                                setOpenMenu(!openMenu);
                            }}
                        >
                            POČETNA
                        </Link>
                        <i
                            className='fas fa-caret-down'
                            onClick={() => {
                                setOpenHomeDropdown(!openHomeDropdown);
                                setOpenProjectsDropdown(false);
                            }}
                        />
                    </div>
                    {openHomeDropdown && (
                        <div
                            className='dropdownLink'
                            onClick={() => {
                                goToPageSection('/', -200, aboutRef);
                                setOpenHomeDropdown(false);
                                setOpenProjectsDropdown(false);
                                setOpenMenu(false);
                            }}
                        >
                            <p>O NAMA</p>
                        </div>
                    )}
                    <div className='linkRow'>
                        <a
                            onClick={() => {
                                window.scrollTo(0, 0);
                                setOpenMenu(!openMenu);
                            }}
                        >
                            PROJEKTI U IZGRADNJI
                        </a>
                        <i
                            className='fas fa-caret-down'
                            onClick={() => {
                                setOpenProjectsDropdown(!openProjectsDropdown);
                                setOpenHomeDropdown(false);
                            }}
                        />
                    </div>
                    {openProjectsDropdown && (
                        <>
                            <div
                                className='dropdownLink'
                                onClick={() => {
                                    window.location.href = '/projekti_u_izgradnji/mejasi';
                                    setOpenHomeDropdown(false);
                                    setOpenProjectsDropdown(false);
                                    setOpenMenu(false);
                                }}
                            >
                                <p>MEJAŠI</p>
                            </div>
                        </>
                    )}
                    <div className='linkRow'>
                        <Link
                            to='/zavrseni_projekti'
                            onClick={() => {
                                window.scrollTo(0, 0);
                                setOpenMenu(!openMenu);
                            }}
                        >
                            ZAVRŠENI PROJEKTI
                        </Link>
                    </div>
                    <div className='linkRow'>
                        <Link
                            to='/kontakt'
                            onClick={() => {
                                window.scrollTo(0, 0);
                                setOpenMenu(!openMenu);
                            }}
                        >
                            KONTAKT
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
}
