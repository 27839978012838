import React from 'react';

interface Props {
    notificationText: string;
    notificationType: string;
    showNotification: boolean;
}

export default function Notification({ notificationText, notificationType, showNotification }: Props) {
    return (
        <>
            {showNotification && (
                <div className={'notification ' + (notificationType === 'success' ? '' : 'errorNotification')}>
                    {notificationText}
                </div>
            )}
        </>
    );
}
