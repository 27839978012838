import { useState, useLayoutEffect } from 'react';

export const useWindow = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useLayoutEffect(() => {
        function updateSize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return { windowWidth };
};
