import React from 'react';

import { useWindow } from '../../hooks/useWindow';

import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';

import './Header.scss';

interface Props {
    goToPageSection: (destinationPathname: string, offset: number, sectionRef?: any) => Promise<void>;
    aboutRef: React.MutableRefObject<any>;
}

export default function Header({ goToPageSection, aboutRef }: Props) {
    const { windowWidth } = useWindow();

    return (
        <div className='headerContainer'>
            {windowWidth >= 780 ? (
                <DesktopHeader aboutRef={aboutRef} goToPageSection={goToPageSection} />
            ) : (
                <MobileHeader aboutRef={aboutRef} goToPageSection={goToPageSection} />
            )}
        </div>
    );
}
