import { useRef } from 'react';
import { history } from '../../App';

export const usePageRedirection = () => {
    const aboutRef: any = useRef(null);

    const scrollToSection = async (offset: number, sectionRef: any = undefined) => {
        if (sectionRef && sectionRef.current) {
            window.scrollTo(0, 0);
            window.scrollTo(0, sectionRef.current.y + offset);
        } else window.scrollTo(0, offset);
    };

    const goToPageSection = async (
        destinationPathname: string,
        offset: number,
        sectionRef: any = undefined
    ): Promise<void> => {
        await history.push(destinationPathname);
        await scrollToSection(offset, sectionRef);
    };

    return {
        aboutRef,
        goToPageSection,
    };
};
