import React, { useRef } from 'react';

import { useGallery } from '../hooks/useGallery';
import { useWindow } from '../hooks/useWindow';

interface Props {
    images: Array<string>;
}

export default function Gallery({ images }: Props) {
    const { gallery, currentImageIndex, selectImage, slideImages, showNextImage, showPreviousImage } = useGallery({
        images,
    });
    const { windowWidth } = useWindow();

    const imageRef: any = useRef();

    return (
        <div className='galleryWrapper'>
            <h1>Galerija</h1>
            <div className='galleryContainer'>
                <div className='imageContainer' ref={imageRef}>
                    <div className='slider' id='slideNext' onClick={showNextImage}>
                        <i className='fas fa-caret-right' color='white' />
                    </div>
                    <div className='slider' id='slidePrevious' onClick={showPreviousImage}>
                        <i className='fas fa-caret-left' color='white' />
                    </div>
                    <img src={gallery[currentImageIndex]} alt='galleryPreview' />
                </div>
                {windowWidth >= 1200 && (
                    <div className='imageSlides' style={{ height: imageRef.current?.offsetHeight }}>
                        {slideImages[0] && (
                            <img
                                className={gallery[currentImageIndex] === slideImages[0] ? 'selected' : ''}
                                src={slideImages[0]}
                                onClick={() => selectImage(slideImages[0])}
                                alt='slideImage'
                            />
                        )}
                        {slideImages[1] && (
                            <img
                                className={gallery[currentImageIndex] === slideImages[1] ? 'selected' : ''}
                                src={slideImages[1]}
                                onClick={() => selectImage(slideImages[1])}
                                alt='slideImage'
                            />
                        )}
                        {slideImages[2] && (
                            <img
                                className={gallery[currentImageIndex] === slideImages[2] ? 'selected' : ''}
                                src={slideImages[2]}
                                onClick={() => selectImage(slideImages[2])}
                                alt='slideImage'
                            />
                        )}
                        {slideImages[3] && (
                            <img
                                className={gallery[currentImageIndex] === slideImages[3] ? 'selected' : ''}
                                src={slideImages[3]}
                                onClick={() => selectImage(slideImages[3])}
                                alt='slideImage'
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
