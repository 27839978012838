import React, { useEffect, useState } from 'react';

import { useFloorMap } from '../hooks/useFloorMap';
import { history } from '../../App';
import { useWindow } from '../hooks/useWindow';
import { useBuilding } from 'utils/hooks/useBuilding';

import logo from '../../assets/icons/RED-LOGO.png';
import FloorsList from './FloorsList';
import FloorMenu from './FloorMenu';

const ImageMapper = require('react-image-mapper');

interface Props {
    buildingTitle: string;
    buildingName: string;
    buildingLink: string;
    buildingData: Manas.Building | undefined;
    projectName: 'mejasi';
    images: string[];
    showText?: boolean;
}

export default function Building({
    buildingName,
    buildingTitle,
    buildingLink,
    buildingData,
    projectName,
    images,
    showText,
}: Props) {
    const [image, setImage] = useState<string>(images[0]);
    const [floor, setFloor] = useState<number>(-1);
    const [floorMap, setFloorMap] = useState<Manas.ClickableMap>({ name: '', areas: [] });
    const { windowWidth } = useWindow();
    const { findNewImage, calculateMapperWidth, parseFloorNumber } = useBuilding();
    const { buildingMap, createFloorMap, setFloorAvailability, mapHandler } = useFloorMap({
        buildingData,
        buildingName,
        projectName,
        floor,
        parseFloorNumber,
    });

    const [mapperWidth, setMapperWidth] = useState<number>(calculateMapperWidth(windowWidth));
       
    useEffect(() => {
        setMapperWidth(calculateMapperWidth(windowWidth));
    }, [calculateMapperWidth, windowWidth]);

    const updateMap = async (floor: any) => {
        const image = findNewImage(images, buildingName, floor);
        setImage(image);
        setFloorMap(setFloorAvailability(floor, createFloorMap()));  
    };

    useEffect(() => {
        updateMap(floor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingData, floor]);

    return ( 
        <><div className='floors'>
        <div
            onClick={() => {
                history.push(`/projekti_u_izgradnji/${projectName}/${buildingLink}`);
                setFloor(-1);
                setImage(images[0]);
            }}
            className='logoBuilding'
        >
            <img src={logo} alt='logo' />
            <h1>Zgrada {buildingTitle}</h1>
        </div>
        {windowWidth > 900 ? (
            <div className={`floorsDropdown ${buildingName === 'MA' ? 'right_position_0' : ''}`}>
                <FloorsList
                    buildingName={buildingName}
                    floor={floor}
                    setFloor={setFloor}
                    buildingLink={buildingLink}
                    projectName={projectName}
                />
            </div>
        ) : (
            <FloorMenu buildingName={buildingName} buildingLink={buildingLink} projectName={projectName} setFloor={setFloor} />
        )}
    </div>
    {floorMap && (
        <div className='floorContainer'>
            <ImageMapper
                src={image}
                map={floor >= 0 ? floorMap : buildingMap}
                width={mapperWidth}
                style={{ mapperWidth: 'auto', innerWidth: 'auto' }}
                imgWidth={700}
                onClick={(area: any) => {
                    mapHandler(floor, area.name, setFloor, floor < 0 && area.name);
                }}
            />
        </div>
    )}</>
            
    );
}
