import { GoogleSpreadsheet } from 'google-spreadsheet';

export async function getSheetData() {
    const doc: GoogleSpreadsheet = new GoogleSpreadsheet('1-2XiDrtAf8hWTAR5EiT9WKmc9gJP0RnMHfSfqYb50rQ');

    // await doc.useServiceAccountAuth(require(__dirname + '/' + config.credentialsPath));

    await doc.useServiceAccountAuth({
        client_email: 'manas-471@manas-1598084674789.iam.gserviceaccount.com',
        private_key:
            '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCurX3yiZzLeGPA\nuNte3xfBbh4THXE0rek7DY/9KDoyJSUquF3PP5VN9OOeXj8gz0VZnxIQ3J63SvjJ\nHb1WrFpie3TR/od5kn1ZR876gQ0L6ibIcOi4Kttd8G2CCiEnpK/zcviTZnnMwXJ3\neyYsfAlAPq6olQIjrq/22bMQwSivOILaZb3YhGJrxUGfQkrI6bsuxxDC/YJuxqt1\nQRL2LbWIw5OsOhnSDLQFa2zAfXNYH9HJPPG9c7DTksENmcrhoKzEoK7xCybZ+eh6\nmI3TdadTiSDtgsrSt28wdUpNyGP3E+AN01AeI4wLRgQ/t4tbDFCMibcBlr5yTqh2\nJ9/009zDAgMBAAECggEAPMzXn4HFyU7szcqqVOHrV2E4Z6fiXRdBrNPiMBh99TFX\nuAB3aqlzkzBvwBKG/6kVtwW22JaqSht28C4BVvdj+I6HUYa6ahcSuW5UvX4I6AJH\nIJKodl44gACtZUuYUb+xnNgCbPAI6jvVV4BOf8Jmx+1phvViyr/FsPMCsIzDWAWL\nmASyJRlQtDfhCx7mg8m56i9OS99vfhnBLVIFOInuxgfP6jYO5plvv4L/LnhRBdcE\n2hu5/fKPrpFmkp6RDvftEMRJfRhhtQ6DiRrU7y1ejqlOmbggVSj3CPUGQ53H/s+m\nDHIMHPIYWAclABdFq/fUlspKKAv0EBWlpl64lDrt0QKBgQDwsKCxkq/x5y6lJlZB\n1PYzvkIs6lTB0tCpA1VdWyMsF5IYaMHd80Ez/DhVv74tq6JC4NVxrB/5ihotz+c/\nJD+0hY4V3AL1EeQU1puAdqv/Uh2vDrSCCwfpXpISqUbwaCt62aBHgk2iuS+Z5x5+\n3igXlKGZkZOpI8swD7AXTUBfMwKBgQC5ye1Vpa5O2D6uQocSL9ZDrIuYevAwrQFK\nYqrSKn7ZGWevtKMBv2btNrcEsaVh1D1hd4RnB04Hxc2bFGLdYHUYwtp7qfdQA/RP\nWXeyJBgkpwqjKhsKFTN6sChvSB9N972FjStm1+glAuKyVc/HC+d81DWmtj8LGAgx\n8463pcfMMQKBgHk2qqbjFeLAp5gad4RN6PPkFFWkaaz3K4iCmkIavNrev/+0+jV5\n5VN16TUeDHvZnS0LJ9NIeQ3mBAsgUfhVnBOg0w6lL4QWT4RDFCsdGL/LrhFUWYnI\nXmKj8vKc95HpFESZRwPcVpblplhesdf4r744Zy38x5193j1FGUyS5iFLAoGAZRDe\npb+6QbPK7TrNhWw2b5v2WF1QOfpzAEcKS8fIN0tlpyRppDK9x/8Csm/82mn9df7x\nLKbBoOLOhTUizvCA4pZlCe6c1Y9BIMU3otWVuKKR28605NhBp9UaOecO+HfzRipU\nWN7Mrw2Rypd5rvrf030RLn5nbx6Enr+yfF6XgJECgYAaEiQASBvv2ZdWJJQt+JEK\nzeFVm0xWJg0dz/XIQi6ryjAHE+2b4B9YGJBN8Fm+5TcMKZTnn0kDG8u6se0gnO5B\n57Tm+dzPBfR29LhtGwS0DqijZNt427cUK6s397qF5rLp4xARm0JmyOtLTqggI86a\nhhEJDoYKugZISAVuNjcR8w==\n-----END PRIVATE KEY-----\n',
    });

    await doc.loadInfo();

    const mejasiDetailsSheet = doc.sheetsByIndex[5];
    const mejasiDetailsRows = await mejasiDetailsSheet.getRows();
    const mejasiDetails: Manas.ProjectDetails = {
        apartmentUnits: mejasiDetailsRows[0]['Broj stambenih jedinica'],
        garageSpots: mejasiDetailsRows[0]['Garazna mjesta'],
        buildingStartDate: mejasiDetailsRows[0]['Pocetak gradnje'],
        buildingEndDate: mejasiDetailsRows[0]['Planirani zavrsetak gradnje'],
        progressPercentage: mejasiDetailsRows[0]['Dovrsetak'],
        planningPercentage: mejasiDetailsRows[0]['Planiranje i projektiranje'],
        buildingPhasePercentage: mejasiDetailsRows[0]['Faza gradnje'],
        interierPercentage: mejasiDetailsRows[0]['Uredivanje interijera'],
    };

    const mejasiASheet = doc.sheetsByIndex[6];
    const mejasiARows = await mejasiASheet.getRows();
    const mejasiA: Manas.Building = {
        '2': [
            mejasiARows[0]['Stan br. 1'],
            mejasiARows[0]['Stan br. 2'],
        ],
        '3': [
            mejasiARows[1]['Stan br. 1'],
            mejasiARows[1]['Stan br. 2'],
            mejasiARows[1]['Stan br. 3'],
            mejasiARows[1]['Stan br. 4'],
            mejasiARows[1]['Stan br. 5'],
        ],
        '4': [
            mejasiARows[2]['Stan br. 1'],
            mejasiARows[2]['Stan br. 2'],
            mejasiARows[2]['Stan br. 3'],
            mejasiARows[2]['Stan br. 4'],
            mejasiARows[2]['Stan br. 5'],
        ],
        '5': [
            mejasiARows[3]['Stan br. 1'],
            mejasiARows[3]['Stan br. 2'],
            mejasiARows[3]['Stan br. 3'],
            mejasiARows[3]['Stan br. 4'],
            mejasiARows[3]['Stan br. 5'],
        ],
        '6': [
            mejasiARows[4]['Stan br. 1'],
            mejasiARows[4]['Stan br. 2'],
            mejasiARows[4]['Stan br. 3'],
            mejasiARows[4]['Stan br. 4'],
            mejasiARows[4]['Stan br. 5'],
        ],
        '7': [
            mejasiARows[5]['Stan br. 1'], 
            mejasiARows[5]['Stan br. 2'],
        ],
    };

    const mejasiBSheet = doc.sheetsByIndex[7];
    const mejasiBRows = await mejasiBSheet.getRows();
    const mejasiB: Manas.Building = {
        '1': [
            mejasiBRows[0]['Stan br. 1'], 
            mejasiBRows[0]['Stan br. 2'],
        ],
        '2': [
            mejasiBRows[1]['Stan br. 1'],
            mejasiBRows[1]['Stan br. 2'],
            mejasiBRows[1]['Stan br. 3'],
            mejasiBRows[1]['Stan br. 4'],
            mejasiBRows[1]['Stan br. 5'],
            mejasiBRows[1]['Stan br. 6'],
            mejasiBRows[1]['Stan br. 7'],
            mejasiBRows[1]['Stan br. 8'],
            mejasiBRows[1]['Stan br. 9'],
            mejasiBRows[1]['Stan br. 10'],
            mejasiBRows[1]['Stan br. 11'],
            mejasiBRows[1]['Stan br. 12'],
            mejasiBRows[1]['Stan br. 13'],
            mejasiBRows[1]['Stan br. 14'],
            mejasiBRows[1]['Stan br. 15'],
            mejasiBRows[1]['Stan br. 16'],
            mejasiBRows[1]['Stan br. 17'],
            mejasiBRows[1]['Stan br. 18'],
        ],
        '3': [
            mejasiBRows[2]['Stan br. 1'],
            mejasiBRows[2]['Stan br. 2'],
            mejasiBRows[2]['Stan br. 3'],
            mejasiBRows[2]['Stan br. 4'],
            mejasiBRows[2]['Stan br. 5'],
            mejasiBRows[2]['Stan br. 6'],
            mejasiBRows[2]['Stan br. 7'],
            mejasiBRows[2]['Stan br. 8'],
            mejasiBRows[2]['Stan br. 9'],
            mejasiBRows[2]['Stan br. 10'],
            mejasiBRows[2]['Stan br. 11'],
            mejasiBRows[2]['Stan br. 12'],
            mejasiBRows[2]['Stan br. 13'],
            mejasiBRows[2]['Stan br. 14'],
            mejasiBRows[2]['Stan br. 15'],
            mejasiBRows[2]['Stan br. 16'],
            mejasiBRows[2]['Stan br. 17'],
            mejasiBRows[2]['Stan br. 18'],
        ],
        '4': [
            mejasiBRows[3]['Stan br. 1'],
            mejasiBRows[3]['Stan br. 2'],
            mejasiBRows[3]['Stan br. 3'],
            mejasiBRows[3]['Stan br. 4'],
            mejasiBRows[3]['Stan br. 5'],
            mejasiBRows[3]['Stan br. 6'],
            mejasiBRows[3]['Stan br. 7'],
            mejasiBRows[3]['Stan br. 8'],
            mejasiBRows[3]['Stan br. 9'],
            mejasiBRows[3]['Stan br. 10'],
            mejasiBRows[3]['Stan br. 11'],
            mejasiBRows[3]['Stan br. 12'],
            mejasiBRows[3]['Stan br. 13'],
            mejasiBRows[3]['Stan br. 14'],
            mejasiBRows[3]['Stan br. 15'],
            mejasiBRows[3]['Stan br. 16'],
            mejasiBRows[3]['Stan br. 17'],
            mejasiBRows[3]['Stan br. 18'],
        ],
        '5': [
            mejasiBRows[4]['Stan br. 1'],
            mejasiBRows[4]['Stan br. 2'],
            mejasiBRows[4]['Stan br. 3'],
            mejasiBRows[4]['Stan br. 4'],
            mejasiBRows[4]['Stan br. 5'],
            mejasiBRows[4]['Stan br. 6'],
            mejasiBRows[4]['Stan br. 7'],
            mejasiBRows[4]['Stan br. 8'],
            mejasiBRows[4]['Stan br. 9'],
            mejasiBRows[4]['Stan br. 10'],
            mejasiBRows[4]['Stan br. 11'],
            mejasiBRows[4]['Stan br. 12'],
            mejasiBRows[4]['Stan br. 13'],
            mejasiBRows[4]['Stan br. 14'],
            mejasiBRows[4]['Stan br. 15'],
            mejasiBRows[4]['Stan br. 16'],
            mejasiBRows[4]['Stan br. 17'],
            mejasiBRows[4]['Stan br. 18'],
        ],
        '6': [
            mejasiBRows[5]['Stan br. 1'],
            mejasiBRows[5]['Stan br. 2'],
            mejasiBRows[5]['Stan br. 3'],
            mejasiBRows[5]['Stan br. 4'],
            mejasiBRows[5]['Stan br. 5'],
            mejasiBRows[5]['Stan br. 6'],
            mejasiBRows[5]['Stan br. 7'],
            mejasiBRows[5]['Stan br. 8'],
            mejasiBRows[5]['Stan br. 9'],
            mejasiBRows[5]['Stan br. 10'],
            mejasiBRows[5]['Stan br. 11'],
            mejasiBRows[5]['Stan br. 12'],
            mejasiBRows[5]['Stan br. 13'],
            mejasiBRows[5]['Stan br. 14'],
            mejasiBRows[5]['Stan br. 15'],
            mejasiBRows[5]['Stan br. 16'],
            mejasiBRows[5]['Stan br. 17'],
            mejasiBRows[5]['Stan br. 18'],
        ],
        '7': [
            mejasiBRows[6]['Stan br. 1'],
            mejasiBRows[6]['Stan br. 2'],
            mejasiBRows[6]['Stan br. 3'],
            mejasiBRows[6]['Stan br. 4'],
            mejasiBRows[6]['Stan br. 5'],
        ],
    };

    return {
        mejasiDetails,
        mejasiA,
        mejasiB,
    };
}
