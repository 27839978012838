import React from 'react';

import { useGoogleSheet } from '../../utils/hooks/useGoogleSheet';

import ProjectMejasi from './mejasi/ProjectMejasi';

import './IncomingProjects.scss';

export default function IncomingProjects() {
    const { mejasiDetails, mejasiA, mejasiB } = useGoogleSheet();

    return (
        <div className='projectsWrapper'>
            {window.location.href.includes('mejasi') && (
                <ProjectMejasi projectDetails={mejasiDetails} buildingA={mejasiA} buildingB={mejasiB} />
            )}
        </div>
    );
}
