import React from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { usePageRedirection } from './utils/hooks/usePageRedirection';

import Header from './utils/components/header/Header';
import Home from './tabs/home/Home';
import IncomingProjects from './tabs/incomingProjects/IncomingProjects';
import FinishedProjects from './tabs/finishedProjects/FinishedProjects';
import Contact from './tabs/contact/Contact';
import Footer from './utils/components/footer/Footer';

import './App.scss';

export const history = createBrowserHistory();

export default function App() {
    const { aboutRef, goToPageSection } = usePageRedirection();

    return (
        <div className='App'>
            <Router history={history}>
                <Header goToPageSection={goToPageSection} aboutRef={aboutRef} />
                <Route exact path='/' render={() => <Home aboutRef={aboutRef} />} />
                <Route path='/projekti_u_izgradnji/*' render={() => <IncomingProjects />} />
                <Route path='/zavrseni_projekti' component={FinishedProjects} />
                <Route path='/kontakt' component={Contact} />
                <Footer />
            </Router>
        </div>
    );
}
