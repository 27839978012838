import React from 'react';
import Cookies from 'js-cookie';

interface Props {
    toggleCookieAbout: React.Dispatch<React.SetStateAction<boolean>>;
    closeCookieConsent: () => void;
    isToggled: boolean;
}

export default function CookiePopup({ toggleCookieAbout, closeCookieConsent, isToggled }: Props) {
    const acceptCookiesHandler = () => {
        Cookies.set('consent_cookie', 'consent');
        toggleCookieAbout(false);
        closeCookieConsent();
    };

    return (
        <div className='cookieContainer'>
            <div className='controlsContainer'>
                <div className='controlsText'>
                    Ova stranica koristi kolačiće.
                    <br />
                    Kako bi vam pružili najbolje korisničko iskustvo,
                    <br />
                    naša web stranica može koristiti kolačiće.
                </div>
                <div className='controlsOptions'>
                    <button className='primaryButton' onClick={acceptCookiesHandler}>
                        PRIHVAĆAM
                    </button>
                    <button
                        className='secondaryButton'
                        onClick={() => {
                            toggleCookieAbout(false);
                            closeCookieConsent();
                        }}
                    >
                        NE PRIHVAĆAM
                    </button>
                    <button className='secondaryButton' onClick={() => toggleCookieAbout(!isToggled)}>
                        {isToggled ? 'ZATVORI' : 'SAZNAJTE VIŠE'}
                    </button>
                </div>
            </div>
        </div>
    );
}
